import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ModalBlock } from '../../components/Modal';

const useStyles = makeStyles(() => ({
  modalBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
}));

export const ModalError = ({ open, onCancel, errorText }) => {
  const classes = useStyles();

  return (
    <ModalBlock open={open} title="Error">
      <p>{errorText}</p>
      <div className={classes.modalBtn}>
        <Button variant="contained" color="primary" onClick={onCancel}>
          Ok
        </Button>
      </div>
    </ModalBlock>
  );
};
