import { groupBy, test as RamdaTest } from 'ramda';
import React from 'react';
import { usePermissions } from 'ra-core';
import { NasRow } from './NasRow';

export const NasList = ({ nasDocuments = [] }) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions === 'authenticated';
  const documents = groupBy((path) => {
    if (RamdaTest(/\/Vyroba\//, path)) {
      return 'production';
    }
    if (RamdaTest(/\/Filmy\//, path)) {
      return 'films';
    }

    return 'graphics';
  }, nasDocuments);

  return (
    <>
      <NasRow name="graphics" documents={documents?.graphics} />
      {isAdmin && <NasRow name="production" documents={documents?.production} />}
      <NasRow name="films" documents={documents?.films} />
    </>
  );
};
