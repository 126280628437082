import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';
import { LoadingIndicator, UserMenu } from 'react-admin';
import MyMenu from './MyMenu';

const useStyles = makeStyles((theme) => ({
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: theme.spacing(1),
  },
  menuItems: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const MyAppBar = ({ color = 'secondary', ...props }) => {
  const classes = useStyles();

  return (
    <AppBar {...props} color={color} open={false}>
      <Toolbar variant="dense">
        <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
        <MyMenu />
        <div className={classes.menuItems}>
          <LoadingIndicator />
          {React.cloneElement(<UserMenu />, { logout: props.logout })}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
