import * as React from 'react';
import { Show } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { ContractShow } from './components/ContractShow';
import { AsideAudit } from './audit/AsideAudit';

const useStyles = makeStyles(({ layout }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: layout.pageWidth,
  },
}));

const ShowContract = (props) => {
  const classes = useStyles();
  return (
    <Show {...props} className={classes.root} actions={false} aside={<AsideAudit />}>
      <ContractShow {...props} />
    </Show>
  );
};

export default ShowContract;
