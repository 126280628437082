import * as React from 'react';
import { Resource } from 'react-admin';
import ContractCreate from './Create';
import ContractEdit from './Edit';
import ContractList from './List';
import ShowContract from './Show';

export default (
  <Resource
    options={{ label: 'Zakázky' }}
    name="contracts"
    list={ContractList}
    edit={ContractEdit}
    create={ContractCreate}
    show={ShowContract}
  />
);
