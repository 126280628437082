import * as React from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { getContractStatusName } from '../contract/utils';
import { getVerboseDateTime } from '../helpers/formatDate';

const useStyles = makeStyles(({ spacing }) => ({
  shapeCircle: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    marginRight: spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const statusColors = {
  unresolved: 'red',
  in_process: 'orange',
  resolve: 'green',
  administration: 'blue',
  done: 'black',
};

const getStatusColor = (status) => statusColors[status] || statusColors.unresolved;

const CustomIdWithStatus = ({ source: idSource, statusSource, record = {} }) => {
  const translate = useTranslate();

  const status = record[statusSource];
  const idText = record[idSource];
  const classes = useStyles();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formatedDate = getVerboseDateTime(new Date(idText).toISOString(), options);
  return (
    <Tooltip title={`Status: ${translate(getContractStatusName(status))}`}>
      <Typography component="div" className={classes.root} variant="body2">
        <div style={{ backgroundColor: getStatusColor(status) }} className={classes.shapeCircle} />
        {formatedDate}
      </Typography>
    </Tooltip>
  );
};

export default CustomIdWithStatus;
