import * as React from 'react';
import { Box, Button, Divider, Grid, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { useNotify, usePermissions, useTranslate } from 'ra-core';
import {
  ArrayInput,
  DateInput,
  FileInput,
  FileField,
  FormWithRedirect,
  ListButton,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleFormIterator,
  TextInput,
  TabbedShowLayout,
  Tab,
} from 'react-admin';
import { __, any, equals, includes, indexOf, isNil, keys } from 'ramda';
import { diff } from 'json-diff';
import { FilterPotentialDealers } from '../../helpers/user';
import SizesList from './SizesList';
import { ModalConfirm } from './ModalConfirm';
import './../../index.css';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  buttonGroup: {
    width: '100%',
  },
  inlineBlock: {
    width: '100%',
    display: 'inline-flex',
    marginRight: spacing(2),
    [breakpoints.up('sm')]: {
      width: 190,
    },
    [breakpoints.up('md')]: {
      width: 170,
    },
    [breakpoints.up('lg')]: {
      width: 190,
    },
    [breakpoints.between('lg', 1414)]: {
      width: '100%',
    },
    [breakpoints.up('xl')]: {
      width: 240,
    },
  },
  sizeBlock: {
    maxWidth: 90,
    display: 'inline-flex',
    marginRight: 16,
  },
  noHeight: {
    height: 0,
  },
  productionArea: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      flex: '1',
    },
  },
  closeButton: {
    marginLeft: spacing(2),
  },
  uploadFile: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    zIndex: '1',
    marginTop: '8px',
    background: '#fafafa',
  },
  fileInput: {
    minHeight: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    paddingTop: '4px',
    marginBottom: '-10px',
  },
  hidden: {
    visibility: 'hidden',
  },
  administration: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const graphicStatusChoices = [
  { id: 'unresolved', name: 'resources.contracts.fields.graphic_options.unresolved' },
  { id: 'in_process', name: 'resources.contracts.fields.graphic_options.in_process' },
  { id: 'resolve', name: 'resources.contracts.fields.graphic_options.resolved' },
];

const materialStatusChoices = [
  { id: 'unresolved', name: 'resources.contracts.fields.material_options.unresolved' },
  { id: 'in_process', name: 'resources.contracts.fields.material_options.in_process' },
  { id: 'resolve', name: 'resources.contracts.fields.material_options.resolved' },
];

const billingStatusChoices = [
  { id: 'unresolved', name: 'resources.contracts.fields.billing_options.unresolved' },
  { id: 'resolve', name: 'resources.contracts.fields.billing_options.resolved' },
];

export const contractStatusChoices = [
  {
    id: 'unresolved',
    name: 'resources.contracts.fields.contract_options.unresolved',
  },
  {
    id: 'in_process',
    name: 'resources.contracts.fields.contract_options.in_process',
  },
  {
    id: 'administration',
    name: 'resources.contracts.fields.contract_options.administration',
  },
  {
    id: 'done',
    name: 'resources.contracts.fields.contract_options.done',
  },
];

export const contractStatusSequence = ['unresolved', 'in_process', 'resolve', 'administration', 'done'];

const listOfImportantFields = [
  'name',
  'customer_name',
  'customer_in',
  'customer_tin',
  'deadline_date',
  'dealer',
  'description',
  'files',
  'materials',
];

export const isDesignerChange = (oldRecord, newRecord) => {
  const changes = diff(oldRecord, newRecord);
  return any(includes(__, ['film_files, graphics_status']), keys(changes));
};

export const isBusinessFileUploadChange = (oldRecord, newRecord) => {
  const changes = diff(oldRecord, newRecord);
  return equals(['files'], keys(changes));
};

export const isBusinessChange = (oldRecord, newRecord) => {
  const changes = diff(oldRecord, newRecord);
  return any(includes(__, listOfImportantFields), keys(changes));
};

export const isUnresolved = (currentStatus) => isNil(currentStatus) || currentStatus === contractStatusSequence[0];

const EditContractForm = (props) => {
  const {
    record: { contract_status: contractStatus },
    save,
    isNew,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const notify = useNotify();

  const isAdmin = permissions === 'authenticated';
  const isDealer = permissions === 'dealer';
  const isDesigner = permissions === 'designer';

  const isDealerAndAccepted = isDealer && !isUnresolved(contractStatus);

  const [open, setOpen] = React.useState(false);
  const [saveParams, setSaveParams] = React.useState({});
  const [recordBackup] = React.useState(props.record);
  const [prevContractStatus] = React.useState(contractStatus);

  const onCancel = React.useCallback(() => setOpen(false), []);

  const handleConfirm = React.useCallback(() => {
    save(...saveParams);
    setOpen(false);
  }, [save, saveParams]);

  const handleSave = React.useCallback(
    (...saveArgs) => {
      if (isDealer && isBusinessFileUploadChange(recordBackup, saveArgs[0])) {
        save(...saveArgs);
        return;
      }

      if (isDesigner && isDesignerChange(recordBackup, saveArgs[0])) {
        save(...saveArgs);
        return;
      }

      if (isAdmin && !isUnresolved(contractStatus) && isBusinessChange(recordBackup, saveArgs[0])) {
        setOpen(true);
        setSaveParams(saveArgs);
        return;
      }

      save(...saveArgs);
    },
    [recordBackup, isAdmin, isDesigner, isDealer, contractStatus, save]
  );

  const contractStatusChanged = (currentContractStatus) => {
    const oldStateOrder = indexOf(prevContractStatus, contractStatusSequence);
    const newStateOrder = indexOf(currentContractStatus, contractStatusSequence);
    if (oldStateOrder > newStateOrder) {
      notify(translate('resources.contracts.errors.contractStatusChanged'), 'error');
    }
  };

  const changeStateAndSave = (saveFn) => {
    window.setTimeout(saveFn, 50);
  };
  const acceptFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];

  return (
    <FormWithRedirect
      {...props}
      save={handleSave}
      redirect={isNew ? 'edit' : false}
      warnWhenUnsavedChanges
      render={(formProps) => (
        <form>
          <Toolbar variant="dense">
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} // eslint-disable-line react/jsx-handler-names
                />
                {isAdmin && !isNew && isUnresolved(contractStatus) && (
                  <Button
                    style={{ marginLeft: 20 }}
                    color="primary"
                    variant="contained"
                    htmlFor="contract_status_in_process"
                    component={'label'}
                    onClick={() => changeStateAndSave(formProps.handleSubmitWithRedirect)}>
                    {translate('resources.contracts.buttons.accept_order')}
                  </Button>
                )}

                {!isNew && (
                  <ShowButton
                    basePath={props.basePath}
                    record={props.record}
                    resource={formProps.resource}
                    size={'medium'}
                    className={classes.closeButton}
                  />
                )}
              </Box>
              <Box>
                <ListButton basePath={props.basePath} label="Zavřít" size={'medium'} className={classes.closeButton} />
              </Box>
            </Box>
          </Toolbar>
          <Box m={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <TabbedShowLayout className={classes.buttonGroup}>
                <Tab label="Obchodní">
                  <Grid container>
                    <Grid item container xs={12} lg={7}>
                      <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.contracts.labels.project_box')}</Typography>
                        <TextInput
                          disabled={isDesigner || isDealerAndAccepted}
                          source="name"
                          resource={formProps.resource}
                          validate={required()}
                          className={classes.inlineBlock}
                        />
                        <DateInput
                          disabled={isDesigner || isDealerAndAccepted}
                          source="deadline_date"
                          resource={formProps.resource}
                          className={classes.inlineBlock}
                          validate={required()}
                        />
                        {isAdmin && (
                          <ReferenceInput
                            source="dealer.id"
                            label={translate('resources.contracts.fields.dealer')}
                            reference="users"
                            validate={required()}
                            className={classes.inlineBlock}>
                            <FilterPotentialDealers optionText="username" validate={required()} />
                          </ReferenceInput>
                        )}
                        <TextInput
                          disabled={isDesigner || isDealerAndAccepted}
                          source="dealerContractId"
                          className={classes.inlineBlock}
                          resource={formProps.resource}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.contracts.labels.customer_box')}</Typography>
                        <TextInput
                          disabled={isDesigner || isDealerAndAccepted}
                          source="customer_name"
                          resource={formProps.resource}
                          validate={required()}
                          className={classes.inlineBlock}
                        />
                        <TextInput
                          disabled={isDesigner || isDealerAndAccepted}
                          source="customer_in"
                          resource={formProps.resource}
                          className={classes.inlineBlock}
                        />
                        <TextInput
                          disabled={isDesigner || isDealerAndAccepted}
                          source="customer_tin"
                          resource={formProps.resource}
                          className={classes.inlineBlock}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.contracts.labels.production')}</Typography>
                        <Box>
                          <ReferenceInput
                            disabled={isDesigner || isDealerAndAccepted}
                            className={classes.inlineBlock}
                            source="processing.id"
                            reference="type-of-processings"
                            label={translate('resources.contracts.fields.type_of_processing')}>
                            <SelectInput />
                          </ReferenceInput>
                          <TextInput
                            disabled={isDesigner || isDealerAndAccepted}
                            className={classes.inlineBlock}
                            source="color_count"
                            resource={formProps.resource}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    {(isAdmin || isDealer) && (
                      <Grid item xs={12} lg={5}>
                        <Typography variant="h6">{translate('resources.contracts.labels.files_box')}</Typography>
                        <span className={classes.uploadFile}>
                          <FileInput
                            className={classes.fileInput}
                            accept={acceptFileTypes.join(',')}
                            multiple
                            source="files"
                            resource={formProps.resource}
                            label={''}>
                            <FileField source="url" title="name" />
                          </FileInput>
                        </span>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextInput
                        disabled={isDesigner || isDealerAndAccepted}
                        source="save_print"
                        resource={formProps.resource}
                        fullWidth
                        multiline
                        rows={4}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextInput
                        disabled={isDesigner || isDealerAndAccepted}
                        fullWidth
                        multiline
                        rows={5}
                        source="description"
                        resource={formProps.resource}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextInput
                        disabled={isDesigner || isDealerAndAccepted}
                        fullWidth
                        multiline
                        rows={5}
                        source="packing"
                        resource={formProps.resource}
                      />
                    </Grid>
                  </Grid>
                </Tab>
                {(isAdmin || isDealer) && (
                  <Tab label="Materiál" path="materiál">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.contracts.labels.material_box')}</Typography>
                        <ArrayInput source="materials" resource={formProps.resource} disabled={isDealerAndAccepted}>
                          <SimpleFormIterator>
                            <TextInput formClassName={classes.inlineBlock} source="data" />
                            <TextInput formClassName={classes.inlineBlock} source="color" />
                            <NumberInput formClassName={classes.inlineBlock} source="number_of_samples" />
                            <SizesList classes={{ inlineBlock: classes.inlineBlock }} />
                            <br formClassName={classes.noHeight} />
                            <TextInput source="size_note" fullWidth multiline rows={2} />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </Grid>
                    </Grid>
                  </Tab>
                )}

                {(isAdmin || isDesigner) && (
                  <Tab label="Grafika" path="grafika">
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h6">{translate('resources.contracts.labels.films_files_box')}</Typography>
                        <span className={classes.uploadFile}>
                          <FileInput
                            className={classes.fileInput}
                            accept={acceptFileTypes.join(',')}
                            multiple
                            source="film_files"
                            resource={formProps.resource}
                            label={''}>
                            <FileField source="url" title="name" download={false} />
                          </FileInput>
                        </span>
                      </Grid>
                    </Grid>
                  </Tab>
                )}
                {isAdmin && (
                  <Tab label="Výroba" path="výroba">
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {translate('resources.contracts.labels.production_files_box')}
                        </Typography>
                        <span className={classes.uploadFile}>
                          <FileInput
                            className={classes.fileInput}
                            accept={acceptFileTypes.join(',')}
                            multiple
                            source="production_files"
                            resource={formProps.resource}
                            label={''}>
                            <FileField source="url" title="name" download={false} />
                          </FileInput>
                        </span>
                      </Grid>
                    </Grid>
                  </Tab>
                )}
                {(isAdmin || isDesigner) && (
                  <Tab label="Administrace" path="administrace">
                    <Grid item xs={12} className={classes.administration}>
                      {isAdmin && (
                        <RadioButtonGroupInput
                          className={classes.buttonGroup}
                          source="contract_status"
                          resource={formProps.resource}
                          choices={contractStatusChoices}
                          onChange={contractStatusChanged}
                          initialValue="unresolved"
                        />
                      )}

                      <Typography variant="h6">{translate('resources.contracts.labels.state_box')}</Typography>
                      <RadioButtonGroupInput
                        className={classes.buttonGroup}
                        source="graphics_status"
                        resource={formProps.resource}
                        choices={graphicStatusChoices}
                        initialValue="unresolved"
                      />
                      {isAdmin && (
                        <RadioButtonGroupInput
                          className={classes.buttonGroup}
                          source="material_status"
                          resource={formProps.resource}
                          choices={materialStatusChoices}
                          initialValue="unresolved"
                        />
                      )}
                      {isAdmin && (
                        <RadioButtonGroupInput
                          className={classes.buttonGroup}
                          source="billing_status"
                          resource={formProps.resource}
                          choices={billingStatusChoices}
                          initialValue="unresolved"
                        />
                      )}
                    </Grid>
                  </Tab>
                )}
              </TabbedShowLayout>
            </Grid>
          </Box>
          {
            // duplication for Accept order button
          }
          <div className={classes.hidden}>
            <RadioButtonGroupInput
              source="contract_status"
              resource={formProps.resource}
              choices={contractStatusChoices}
              onChange={contractStatusChanged}
              initialValue="unresolved"
            />
          </div>
          <ModalConfirm open={open} onCancel={onCancel} onAccept={handleConfirm} />
        </form>
      )}
    />
  );
};

export default EditContractForm;
