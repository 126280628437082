import * as Sentry from '@sentry/react';
import Cookies from './helpers/Cookies';

const authProvider = (config) => ({
  login: ({ username, password }) => {
    const identifier = username; // strapi expects 'identifier' and not 'username'
    const request = new Request(`${config.REACT_APP_API_URL}/auth/local`, {
      method: 'POST',
      body: JSON.stringify({ identifier, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        Cookies.setCookie('token', response.jwt, 7);
        Cookies.setCookie('role', response.user.role.type, 7);
        Cookies.setCookie('auth', JSON.stringify(response.user), 7);
        Sentry.setUser({ role: response.user.role.type });
      });
  },

  logout: () => {
    Cookies.deleteCookie('token');
    Cookies.deleteCookie('role');
    Cookies.deleteCookie('auth');
    Sentry.setUser({ role: null });
    return Promise.resolve();
  },

  checkAuth: () => (Cookies.getCookie('token') ? Promise.resolve() : Promise.reject(new Error('ErrorCheckAuth'))),

  checkError: ({ status }) => {
    // todo - check!
    if (status === 401) {
      // Cookies.deleteCookie('token');
      // Cookies.deleteCookie('role');
      // Cookies.deleteCookie('auth');
      // return Promise.reject(new Error('ErrorCheckError'));
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const role = Cookies.getCookie('role');
    return role ? Promise.resolve(role) : Promise.reject(new Error('ErrorGetPermissions'));
  },

  getIdentity: () => {
    try {
      const { id, username, email } = JSON.parse(Cookies.getCookie('auth'));
      return Promise.resolve({ id, username, email });
    } catch (error) {
      Sentry.captureException(error);
      return Promise.reject(new Error(error));
    }
  },
});

export default authProvider;
