import React from 'react';

function makeAbsoluteUrl(config) {
  if (config.REACT_APP_API_URL.startsWith('/')) {
    config.REACT_APP_API_URL = window.location.origin + config.REACT_APP_API_URL;
  }

  return config;
}

export function loadAppConfig() {
  return fetch('/config.json')
    .then((response) => response.json())
    .then(makeAbsoluteUrl);
}

export const AppConfigContext = React.createContext({});
