import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { last, split } from 'ramda';
import React, { useContext } from 'react';
import { useNotify } from 'ra-core';
import * as Sentry from '@sentry/react';
import Cookies from '../../helpers/Cookies';
import { AppConfigContext } from '../../config';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  listItemText: {
    overflowWrap: 'anywhere',
  },
}));

const getFile = (url) => {
  const token = Cookies.getCookie('token');

  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
};

const downloadFile = async (file, name) => {
  const blob = await file.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = last(split('/', name));
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const NasItem = ({ document: documentPath }) => {
  const classes = useStyles();
  const { REACT_APP_API_URL } = useContext(AppConfigContext);
  const notify = useNotify();

  const openFile = async () => {
    const file = await getFile(`${REACT_APP_API_URL}/contracts/file?path=${documentPath}`);
    if (file?.ok) {
      downloadFile(file, documentPath);
    } else {
      notify('resources.contracts.errors.fileNotFound', 'error');
      Sentry.addBreadcrumb({
        message: 'Requested file',
        type: 'NAS File',
        level: Sentry.Severity.Debug,
        data: { url: file.url, status: file.statusText },
      });
      Sentry.captureMessage('File not found. NAS error', Sentry.Severity.Fatal);
    }
  };

  return (
    <ListItem button onClick={openFile}>
      <ListItemIcon>
        <ImageIcon />
      </ListItemIcon>
      <ListItemText className={classes.listItemText} primary={last(split('/', documentPath))} />
    </ListItem>
  );
};
