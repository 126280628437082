import * as React from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  link: {
    color: 'inherit',
    height: '100%',
  },
});

const MyMenu = ({ onMenuClick }) => {
  const classes = useStyles();

  const resources = useSelector(getResources);
  return (
    <>
      {resources.map(
        (resource) =>
          resource.hasList && (
            <MenuItemLink
              className={classes.link}
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={(resource.options && resource.options.label) || resource.name}
              onClick={onMenuClick}
            />
          )
      )}
    </>
  );
};

export default withRouter(MyMenu);
