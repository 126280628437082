// for more options see:
// - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
// - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
export const getVerboseDateTime = (date, options = { dateStyle: 'full', timeStyle: 'medium' }) =>
  new Intl.DateTimeFormat('cs-cz', options).format(new Date(date));

export const getDateTime = (date, options = { dateStyle: 'medium', timeStyle: 'medium' }) =>
  new Intl.DateTimeFormat('cs-cz', options).format(new Date(date));

export const getDate = (date, options = { dateStyle: 'full' }) =>
  new Intl.DateTimeFormat('cs-cz', options).format(new Date(date));

export const getTime = (date, options = { timeStyle: 'medium' }) =>
  new Intl.DateTimeFormat('cs-cz', options).format(new Date(date));
