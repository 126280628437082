import * as React from 'react';
import { Admin, fetchUtils, Resource } from 'react-admin';
import translationProvider from './translationProvider';
import authProvider from './authProvider';
import { ContractResource } from './contract';
import simpleRestProvider from './helpers/dataProvider';
import Cookies from './helpers/Cookies';
import MyLayout from './components/MyLayout';
import { theme } from './theme';
import { AppConfigContext } from './config';

const httpClient = (url, options = {}) => {
  const innerOptions = options;
  if (!options.headers) {
    innerOptions.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie('token');
  innerOptions.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, innerOptions);
};
const uploadFields = ['files', 'production_files', 'film_files'];

const dataProvider = (config) => simpleRestProvider(config.REACT_APP_API_URL, httpClient, uploadFields);

const App = ({ config }) => (
  <AppConfigContext.Provider value={config}>
    <Admin
      disableTelemetry
      authProvider={authProvider(config)}
      dataProvider={dataProvider(config)}
      i18nProvider={translationProvider}
      layout={MyLayout}
      theme={theme}>
      {ContractResource}
      <Resource name="users" />
      <Resource name="type-of-processings" />
    </Admin>
  </AppConfigContext.Provider>
);

export default App;
