import * as React from 'react';
import { Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import MyAppBar from './MyAppBar';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  // another hack because layout can have sidebar, but has empty component instead of disabling sidebar
  rootLayout: {
    '& main > div': {
      alignItems: 'center',
      [breakpoints.up('md')]: {
        paddingLeft: spacing(3),
      },
    },
  },
}));

const Empty = () => <></>;

const MyLayout = (props) => {
  const classes = useStyles();
  return <Layout className={classes.rootLayout} {...props} appBar={MyAppBar} sidebar={Empty} />;
};

export default MyLayout;
