import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
}));

export const ModalBlock = ({ open, title, children }) => {
  const classes = useStyles();

  return (
    <Modal open={open}>
      <div className={classes.paper}>
        <h2>{title}</h2>
        {children}
      </div>
    </Modal>
  );
};
