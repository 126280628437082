import * as React from 'react';
import { Create } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import EditContractForm from './components/EditContractForm';

const useStyles = makeStyles(({ layout }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: layout.pageWidth,
  },
}));

const ContractCreate = (props) => {
  const classes = useStyles();
  return (
    <Create {...props} className={classes.root}>
      <EditContractForm {...props} isNew />
    </Create>
  );
};

export default ContractCreate;
