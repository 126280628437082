import czechMessages from 'ra-language-czech';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { mergeDeepRight } from 'ramda';

const myCzechMessage = {
  ra: {
    page: {
      empty: 'Nebyli nalezeny žádné záznamy',
      invite: '',
      error: 'Chyba',
    },
    action: {
      export: 'Exportovat',
      clear_input_value: 'Odstranit hodnoty',
    },
    auth: {
      user_menu: 'Uživatelské menu',
      auth_check_error: 'Chyba příhlášení',
    },
    navigation: {
      page_rows_per_page: 'Počet položek na stránce',
      skip_nav: 'skip_nav',
    },
    notification: {
      logged_out: 'Byl jste odhlášen',
      data_provider_error: 'There was some error either connection with NAS or your internet connection',
    },
    message: {
      unsaved_changes: 'Máte neuložené změny. Opravdu chcete odejít?',
      error: 'Chyba',
    },
  },
  resources: {
    contracts: {
      name: 'Zakázka |||| Zakázky',
      buttons: {
        accept_order: 'Akceptovat zakázku',
      },
      fields: {
        name: 'Název zakázky',
        deadline_date: 'Termín odevzdání',
        description: 'Poznámka',
        customer_name: 'Jméno zákazníka',
        customer_in: 'IČ',
        customer_tin: 'DIČ',
        graphics_status: 'Grafika',
        type_of_processing: 'Druh zpracování',
        color_count: 'Počet Barev',
        save_print: 'Kam umístit tisk',
        number_of_samples: 'Počet vzorků',
        dealerContractId: 'Číslo zakázky',
        sizes: {
          xs: 'XS / 3A',
          s: 'S / 4A',
          m: 'M / 5A',
          l: 'L / 6A',
          xl: 'XL / 7A',
          '2xl': '2XL / 8A',
          '3xl': '3XL / 9A',
          '4xl': '4XL / 10A',
          '5xl': '5XL / 11A',
          '12a': '12A',
          '13a': '13A',
          '14a': '14A',
          count: 'počet',
        },
        graphic_options: {
          unresolved: 'Neřešeno',
          in_process: 'V řešení',
          resolved: 'Vyřešeno',
        },
        material_status: 'Materiál',
        material_options: {
          unresolved: 'Neřešeno',
          in_process: 'V řešení',
          resolved: 'Vyřešeno',
        },
        billing_status: 'Fakturace',
        billing_options: {
          unresolved: 'Nevystavena',
          resolved: 'Vystavena',
        },
        contract_status: 'Realizace zakázky',
        contract_options: {
          unresolved: 'Nová objednávka',
          in_process: 'V realizaci',
          resolved: 'Vyřešeno',
          administration: 'Administrace',
          done: 'Dokončeno',
        },
        materials: 'Materiály',
        created_at: 'Vytvořeno',
        dealer: 'Obchodník',
        data: 'Informace o materiálu',
        size_note: 'Poznámka',
        color: 'Barva',
        id: 'Číslo zakázky',
        q: 'Vyhledávání',
        files: 'Dokumenty',
        filesAdded: 'Dokumenty',
        filesDeleted: 'Dokumenty',
        production_files: 'Dokumenty z výroby',
        production_filesAdded: 'Dokumenty z výroby',
        production_filesDeleted: 'Dokumenty z výroby',
        deletedMaterials: 'Materiál',
        addedMaterials: 'Materiál',
        firstMaterials: 'Materiál',
        processing: 'Zpracování',
        packing: 'Balení',
        film_filesAdded: 'Filmy přidány',
      },
      labels: {
        customer_box: 'Zákazník',
        project_box: 'Projekt',
        state_box: 'Stav zakázky',
        material_box: 'Materiál',
        files_box: 'Dokumenty',
        production_files_box: 'Dokumenty z výroby',
        films_files_box: 'Filmy',
        warning: 'Varování',
        production: 'Specifikace pro výrobu',
      },
      messages: {
        edit_warning: 'Chystáte se změnit zakázku, která je již v realizaci. Chcete pokračovat?',
        editContractError: 'Chyba v zakázkovém listu',
      },
      errors: {
        contractStatusChanged: 'Pozor měníte stav',
        fileNotFound: 'Soubor se nepodařilo stáhnout',
      },
      show: {
        title: 'Zakázkový list',
        customer_name: 'Firma',
        dealer: 'Zakázku dodává',
        production: 'Výroba',
        material: 'Počet kusů - ZÁKAZNÍK',
        material_sample: 'Počet kusů - výroba/vzorek: ',
        sample: 'Požadován vzorek ke schválení:',
        yes: 'ano',
        no: 'ne',
        note: 'Poznámky, vzkaz:',
        processed: 'Zakázku zpracoval:',
        finished: 'Zakázka dokončena:',
        print: 'Tisk: ',
        wrap: 'Balení:',
        sieve: 'Síta:',
        type_of_processing: 'Druh zpracování: ',
        color_count: 'Počet Barev: ',
        save_print: 'Kam umístit tisk: ',
        sizeCategoryId: {
          man: 'Pánská',
          woman: 'Dámská',
          child: 'Dětská',
          other: 'Ostatní',
          undefined: '',
        },
      },
      list: {
        filters: {
          is_done: 'Zobrazit dokončené',
          dealer: 'Obchodník',
          deadline_date_gte: 'K dokončení od',
          deadline_date_lte: 'K dokončení do',
        },
      },
    },
    audits: {
      name: 'Historie změn',
      type: {
        created: 'Vytvořil',
        deleted: 'Smazal',
        updated: 'Změnil',
        added: 'Přidal',
      },
      messages: {
        contractCreated: 'Vytvořil zakázku',
      },
    },
    nasDocuments: {
      name: 'Dokumenty',
      list: {
        graphics: 'Obchodní grafika',
        production: 'Výroba',
        films: 'Filmy',
      },
    },
  },
};

const completeCzechMessages = mergeDeepRight(czechMessages, myCzechMessage);

export default polyglotI18nProvider(() => completeCzechMessages, 'cz');
