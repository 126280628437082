import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { loadAppConfig } from './config';
import Cookies from './helpers/Cookies';
import './index.css';
import reportWebVitals from './reportWebVitals';

const sentryInit = (config) => {
  if (process.env.NODE_ENV !== 'development') {
    const history = createBrowserHistory();

    Sentry.init({
      dsn: 'https://6b393eb6167c4b06bb468e5b48072415@glitchtip.commity.cz/12',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      environment: config.REACT_APP_ENVIRONMENT,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      initialScope: {
        user: { role: Cookies.getCookie('role') },
      },
    });
  }
  return config;
};

function bootstrapApp(config) {
  ReactDOM.render(
    <React.StrictMode>
      <App config={config} />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

loadAppConfig().then(sentryInit).then(bootstrapApp);
