import * as React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  shapeCircle: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    margin: 'auto',
  },
}));

const statusColors = {
  unresolved: 'red',
  in_process: 'orange',
  resolve: 'green',
  administration: 'blue',
  done: 'black',
};

const getStatusColor = (status) => statusColors[status] || statusColors.unresolved;

const StatusCircle = ({ source, record = {} }) => {
  const status = record[source];
  const classes = useStyles();

  return <div style={{ backgroundColor: getStatusColor(status) }} className={classes.shapeCircle} />;
};

export default StatusCircle;
