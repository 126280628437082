import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query, usePermissions, useTranslate } from 'ra-core';
import React from 'react';
import classNames from 'classnames';
import { NasList } from '../nas/NasList';
import { AuditRow } from './AuditRow';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    marginLeft: spacing(3),
    minWidth: 490,
    maxWidth: 490,
    [breakpoints.down('md')]: {
      position: 'absolute',
      top: '100%',
      left: 0,
      width: 'calc( 100% - 48px )',
      margin: spacing(0, 3),
      minWidth: 0,
    },
    [breakpoints.down('sm')]: {
      margin: 0,
      width: '100%',
    },
  },
  audits: {},
  files: {
    maxHeight: 600,
    overflow: 'auto',
  },
  paper: {
    padding: spacing(),
    marginBottom: 20,
  },
}));

export const AsideAudit = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isAdmin = permissions === 'authenticated';
  const { record } = props;

  return (
    <Box className={classNames(classes.root, 'no-print')}>
      <Paper className={classNames(classes.paper, classes.files)}>
        <Typography variant="h4">{translate('resources.nasDocuments.name')}</Typography>
        <NasList nasDocuments={record?.nasDocuments} />
      </Paper>
      {isAdmin && (
        <Paper className={classNames(classes.paper, classes.audits)}>
          <Typography variant={'h4'}>{translate('resources.audits.name')}</Typography>
          {record && (
            <Query
              type="GET_LIST"
              resource="audits"
              payload={{
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'created_at', order: 'DESC' },
                filter: { contract: record.id },
              }}>
              {(response) => {
                if (response.loading) return <CircularProgress />;
                return response.data.map((auditRow) => (
                  <AuditRow audit={auditRow} authorInfo={auditRow.author} key={auditRow.id} />
                ));
              }}
            </Query>
          )}
        </Paper>
      )}
    </Box>
  );
};
