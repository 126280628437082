import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CreateButton } from 'react-admin';
import { usePermissions } from 'ra-core';

const useStyles = makeStyles(() => ({
  createContract: {
    paddingTop: '5px',
  },
}));

const ListActions = ({ basePath }) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions === 'authenticated';
  const isDealer = permissions === 'dealer';

  const classes = useStyles();

  return <Box className={classes.createContract}>{(isAdmin || isDealer) && <CreateButton basePath={basePath} />}</Box>;
};

export default ListActions;
