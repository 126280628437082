import * as React from 'react';
import { Edit } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslate } from 'ra-core';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { AsideAudit } from './audit/AsideAudit';
import EditContractForm from './components/EditContractForm';
import { ModalError } from './components/ModalError';

const useStyles = makeStyles(({ layout }) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  dealerLayout: {
    maxWidth: layout.pageWidth,
  },
}));

const ContractEdit = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  const onCancel = () => setOpen(false);

  const onFailure = (error) => {
    setOpen(true);
    if (error.message === 'Project folder rename failed') {
      setErrorMsg(translate('resources.contracts.messages.projectFolderRenameFailed'));
    } else {
      setErrorMsg(translate('resources.contracts.messages.editContractError'));
    }
    Sentry.captureMessage(error.message, Severity.Error);
  };

  return (
    <>
      <Edit
        aside={<AsideAudit />}
        actions={false}
        {...props}
        onFailure={onFailure}
        className={classNames(classes.root)}
        mutationMode="pessimistic">
        <EditContractForm {...props} />
      </Edit>
      <ModalError open={open} onCancel={onCancel} errorText={errorMsg} />
    </>
  );
};

export default ContractEdit;
