import * as React from 'react';
import { NumberInput, SelectInput } from 'react-admin';
import { FormDataConsumer, useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  sizeBlock: {
    maxWidth: 90,
    display: 'inline-flex',
    marginRight: 16,
  },
}));

export const sizeCategories = {
  man: 'man',
  woman: 'woman',
  child: 'child',
  other: 'other',
};
export const sizesDefinition = {
  [sizeCategories.man]: ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl'],
  [sizeCategories.woman]: ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl'],
  [sizeCategories.child]: ['1-2', '3-4', '5-6', '7-8', '9-11', '12-13', '14-15'],
  [sizeCategories.other]: ['all'],
};

const SizesList = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <SelectInput
        disabled={props.disabled}
        className={props.classes.inlineBlock}
        source={props.source + 'sizeCategoryId'}
        label={'Kategorie'}
        choices={[
          { id: sizeCategories.man, name: translate(`resources.contracts.show.sizeCategoryId.man`) },
          { id: sizeCategories.woman, name: translate(`resources.contracts.show.sizeCategoryId.woman`) },
          { id: sizeCategories.child, name: translate(`resources.contracts.show.sizeCategoryId.child`) },
          { id: sizeCategories.other, name: translate(`resources.contracts.show.sizeCategoryId.other`) },
        ]}
      />
      <FormDataConsumer {...props}>
        {({ getSource, scopedFormData }) => {
          if (scopedFormData) {
            const sizes = sizesDefinition[scopedFormData.sizeCategoryId];
            if (sizes) {
              return (
                <>
                  {scopedFormData.sizeCategoryId !== sizeCategories.other && <br />}
                  {sizes.map((size) => (
                    <NumberInput
                      disabled={props.disabled}
                      className={classes.sizeBlock}
                      key={size}
                      source={getSource('sizes.' + size)}
                      record={scopedFormData}
                      min={0}
                      label={scopedFormData.sizeCategoryId === sizeCategories.other ? 'Počet' : size.toUpperCase()}
                    />
                  ))}
                </>
              );
            }
          }
          return null;
        }}
      </FormDataConsumer>
    </>
  );
};

export default SizesList;
