import { contractStatusChoices } from './components/EditContractForm';

export const saveAction = (values) => ({
  type: 'UPDATE',
  resource: 'contracts',
  payload: { data: values, id: values.id },
});

export const getContractStatusName = (statusCode) =>
  contractStatusChoices.find((status) => status.id === statusCode)?.name || contractStatusChoices[0].name;
