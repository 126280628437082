import * as React from 'react';
import { useMemo } from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { isNil } from 'ramda';

const ContractSize = ({ source, record = {}, ...other }) => {
  const contractSize = useMemo(() => {
    if (isNil(record)) {
      return 0;
    }

    const materials = record[source];
    if (isNil(materials)) {
      return 0;
    }

    let size = 0;
    materials.filter(Boolean).forEach(({ sizes = [] }) => {
      for (const property in sizes) {
        size += parseInt(sizes[property]) || 0;
      }
    });
    return size;
  }, [record, source]);

  return (
    <Tooltip title={`Velikost zakázky`}>
      <Typography component="div" variant="body2">
        {contractSize}
      </Typography>
    </Tooltip>
  );
};

export default ContractSize;
