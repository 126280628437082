import { Box, makeStyles } from '@material-ui/core';
import { usePermissions } from 'ra-core';
import * as React from 'react';
import { BooleanInput, DateInput, Filter, ReferenceInput, TextInput, useTranslate } from 'react-admin';
import { FilterPotentialDealers } from '../../helpers/user';

const useStyles = makeStyles(({ spacing }) => ({
  filterBox: {
    minHeight: '45px',
    marginTop: spacing(0.5),
  },
}));

const ContractFilter = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isAdmin = permissions === 'authenticated';
  const isDealer = permissions === 'dealer';

  return (
    <Box className={classes.filterBox}>
      {(isAdmin || isDealer) && (
        <Filter {...props} style={{ minHeight: '45px' }}>
          <TextInput variant="standard" source="q" alwaysOn margin="dense" />
          {isAdmin && (
            <ReferenceInput
              margin="dense"
              source="dealer"
              label={translate('resources.contracts.list.filters.dealer')}
              reference="users"
              variant="standard"
              alwaysOn>
              <FilterPotentialDealers optionText="username" optionValue="id" />
            </ReferenceInput>
          )}
          <DateInput
            margin="dense"
            label={translate('resources.contracts.list.filters.deadline_date_gte')}
            source="deadline_date_gte"
            variant="standard"
            alwaysOn
          />
          <DateInput
            margin="dense"
            label={translate('resources.contracts.list.filters.deadline_date_lte')}
            source="deadline_date_lte"
            alwaysOn
            variant="standard"
          />
          <BooleanInput source="is_done" label={translate('resources.contracts.list.filters.is_done')} alwaysOn />
        </Filter>
      )}
    </Box>
  );
};

export default ContractFilter;
